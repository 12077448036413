import React, { useEffect, useState } from 'react'
import Pdf from "./assets/pravilnik.pdf";

export default function Pravilnik() {

    useEffect(() => {
        
    }, [])

    const onOpenPravilnikPdf = () =>  {
        window.open(Pdf);
    }

    return (
        <>
            <div className="uk-container">
                <p className="sprejem_v_dom_header">
                    VSEBINA PRAVILNIKA
                </p>

                <div className="uk-child-width-1-5" uk-grid="true">
                    <div className="uk-width-3-5@m uk-width-5-5">
                        <p className="sprejem_v_dom_text">A. PRAVILA ŠTUDENTSKEGA DOMA VINCENCIJ</p>

                        <p className="sprejem_v_dom_text">I. del - Splošne določbe</p>

                        <p className="sprejem_v_dom_text">II. del - Kriteriji in način sprejemanja stanovalcev</p>

                        <p className="sprejem_v_dom_text">III. del - Pravice stanovalcev</p>

                        <p className="sprejem_v_dom_text">IV. del - Dolžnosti stanovalcev</p>

                        <p className="sprejem_v_dom_text">V. del - Ostale določbe</p>

                        <p className="sprejem_v_dom_text">VI. del - Sankcioniranje in pritožbe</p>

                        <p className="sprejem_v_dom_text">B. NAVODILO O VSELITVI</p>

                        <p className="sprejem_v_dom_text">C. PREDSTAVITEV DOMA</p>
                    </div>


                    <div className="uk-width-2-5@m uk-width-5-5">

                        <div className='orange_box_zgoraj'>
                            <p className="inside_text_down_boxes">
                                <a className="inside_href_text_upper_boxes" 
                                    onClick={() => onOpenPravilnikPdf()}
                                    target="_blank">
                                        PRAVILNIK.pdf
                                </a>
                            </p>
                        </div>

                    </div>
                </div>


                {/*<p className="sprejem_v_dom_text_down">
                    Na podlagi Akta o ustanovitvi Študentskega doma Vincencij je Upravni odbor doma na svoji 30. seji dne 16.04.2008 sprejel dopolnitve in potrdil naslednja Pravila Študentskega doma Vincencij.
                </p>*/}
                
            </div>
        </>
    )
}