import React, { useEffect, useState } from 'react'

export default function Home() {

    useEffect(() => {
        
    }, [])

    return (
        <>
            <div className="uk-container">
                <div className="osnovna_stran_zgoraj">
                        <div className="o_nas">
                            <p className="o_nas_text">O nas</p>
                        </div>

                        <div className="procelje_doma_img uk-visible@m"></div>
                </div>

                <div className="nagovor_ravnatelj">
                    <br/>
                    <br/>
                    <br/>
                    <p className="nagovor_ravnatelj_naslov">
                        Dobrodošli na spletni strani katoliškega Študentskega doma Vincencij!
                    </p>
                        <p className="nagovor_ravnatelj_text">
                            <div className="ravnatelj_text_box">
                            <i></i>
                        Vodstvo Študentskega doma Vincencij se trudi, da bi se študenti v njem počutili varno in sprejeto ter bi študentom bile omogočene
        dobre možnosti za kvaliteten študij. Želimo živeti po evangeliju našega Gospoda Jezusa Kristusa. Čeprav zaradi naših nepopolnosti in šibkosti večkrat pademo, ne obupujemo. Vemo namreč, da v življenju nismo sami, da je z nami Bog. Geslo Študentskega doma Vincencij se glasi Eno srce, ena duša, ta enost pa je vsej različnosti navkljub mogoča zgolj in samo, v kolikor je temelj pravi – Jezus Kristus. Potem bomo tudi lahko zares živeli v skladu s krščanskimi vrednotami.
                            </div>
                            <br/>
                            Ne želimo biti »navaden« študentski dom. Takšnih je že dovolj. Smo katoliški študentski dom, ki nosi ime po sv. Vincenciju Pavelskem, zavetniku karitativnih dejavnosti. Spodbujamo udejstvovanje študentov pri delu z ranljivimi osebami – z brezdomci, s starostniki, z vsemi, ki so tako ali drugače ubogi.
                            <br/>
                            <br/>
                            Poleg sobe, kjer študent načeloma preživi največ časa, bi izpostavil tri prostore v našem študentskem domu, ki se mi zdijo ključni za uravnoteženo življenje študenta – kapelo, kuhinjo in učilnico. Kapela kot prostor za duhovno rast, kuhinja kot prostor za socializacijo in potešitev svojih telesnih potreb, učilnica kot prostor za intelektualno rast pri študiju. Prav tako pa v študentskem domu potekajo številne skupine: plesna skupina, športna skupina, delovna skupina, študentska skupina ... Tako se za vsakega študenta najde nekaj, kjer je lahko aktiven, če to le želi.
                            <br/>
                            <br/>
                            Študentski dom Vincencij je tesno povezan s cerkvijo Srca Jezusovega. Skupaj tvorita celoto, zato študente spodbujamo k rednemu obisku edine cerkve v Ljubljani, ki je zgrajena v neogotskem slogu in ki ima vrata vsak dan od jutra do večera odprta za osebno molitev ter obisk svete maše.
                        </p>

                        <p className="nagovor_ravnatelj_podpis">
                            Rok Žlender CM, ravnatelj
                        </p>
                    <br/>
                </div>

                <div className="osnovna_stran_svetniki_in_duhovniki">
                        <div className="svetniki_in_duhovniki_podlaga">
                        </div>

                        <div className="uk-child-width-1-5 no_margin_left" uk-grid="true">
                            <div className="uk-width-3-5@s uk-width-5-5 vincencij_card">
                                <div className="uk-child-width-1-5 no_buttom_margin" uk-grid="true">
                                    <div className="uk-width-1-5 uk-visible@m no_padding_left">
                                        <div className="vincencij_slika"></div>
                                    </div>
                                    <div className="uk-width-4-5@m uk-width-5-5">
                                        <p className="vincencij_text"><br/>Sv. Vincencij Pavelski se je rodil 24. aprila 1581 v vasici Pouy blizu Daxa v južni Franciji. Od svoje matere je podedoval čut za delo, ljubezen do ubogih in stiskanih, od očeta pa zdravo kmečko modrost. Pozneje, ko je v zrelih letih organiziral dobrodelno dejavnost, je iskal navdihe pri svoji materi, ki jo je ljubil bolj »kot vse kraljice sveta«. Prav zato je bolje kot drugi poznal ženski svet in ga znal vključiti v dela krščanske ljubezni.<br/><br/></p>
                                    </div>
                                </div>
                            </div>


                            <div className="uk-width-2-5@s uk-width-5-5 vincencij_text_right">
                                <p className="vincencij_headline">Sv. Vincencij Pavelski</p>
                                <p className="vincencij_right_text">»Gospod me je poslal, da oznanim blagovest ubogim, da oznanim jetnikom prostost in slepim vid,
        da pustim zatirane na prostost.« (prim. Lk4,18)</p>
                                <p className="vincencij_right_text_italic">
                                    Vodilo Vincencija in vseh ustanov, ki so povezane z njim.
                                </p>
                            </div>
                        </div>

                        <div className="uk-child-width-1-5" uk-grid="true">
                            <div className="uk-width-2-5@s uk-width-5-5 vincencij_text_right">
                                <p className="gnidavec_headline">Častitljivi Božji služabnik<br/>Janez Frančišek Gnidovec</p>
                            </div>

                            <div className="uk-width-3-5@s uk-width-5-5 gnidovec_card">
                                <div className="uk-child-width-1-5" uk-grid="true">
                                    <div className="uk-width-4-5@m uk-width-5-5">
                                        <p className="gnidovec_text"><br/>Ta svetniški mož, ki je zajemal vodo milosti iz Božjih studencev, je bil sin Suhe Krajine. Rodil se je 29. septembra leta 1873 v Velikem Lipovcu v župniji Ajdovec (danes škofija Novo mesto). Bil je zelo nadarjen zlasti za jezike, odlikovala pa ga je tudi trdna volja: kar je hotel doseči je tudi dosegel. Po maturi se je brez oklevanja odločil za ljubljansko bogoslovje.<br/><br/></p>
                                    </div>
                                    <div className="uk-width-1-5 uk-visible@m no_padding_left">
                                        <div className="gnidovec_slika"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
           </div>
        </>
    )
}