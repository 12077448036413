

export default function Footer() {

    return (
        <footer>
            <div className="uk-container">
                <div className="footer_box margin_top_large">
                    <p className="footer_text">Študentski dom Vincencij | Tabor 12 1000 Ljubljana</p>
                </div>
            </div>

        </footer>
    );
}