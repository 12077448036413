import React, { useEffect, useState } from 'react'

export default function Kontakt() {

    const [screenWidth, setScreenWidth] = useState(0);

    useEffect(() => {
        setScreenWidth(window.innerWidth);
    }, [window.innerWidth])

    return (
        <>
            <div className="uk-container">
                <div className="uk-container">
                    <div className="kontakt-box uk-child-width-1-3" uk-grid="true">
                        <p className="kontakt-text">Študentski dom Vincencij<br/>Tabor 12<br/>1000 Ljubljana</p>
                        <div className="uk-width-2-3">
                            <p className="kontakt-text">Davčna številka: SI 25073575<br/>TRR: IBAN SI56 0201 4003 5464 425 (NLB d.d.)</p>
                        </div>
                    </div>
                </div>

                <div className="uk-child-width-1-5 no_margin_left" uk-grid="true">
                    <div className="uk-width-3-5 ravnatelj_card">
                        <p className="ravnatelj_headline_text">RAVNATELJ</p>
                        <p className="ravnatelj_info_text">Rok Žlender CM<br/>040 530 787<br/>ravnatelj.sdv@gmail.com</p>
                    </div>

                    <div className="uk-width-2-5 marinko_card">
                        <p className="marinko_headline_text">NAMESTNIK IN UPRAVNIK</p>
                        <p className="marinko_info_text">Boštjan Marinko<br/>031 221 729<br/>bostjan.marinko@gmail.com</p>
                    </div>
                </div>
            </div>
        </>
    )
}