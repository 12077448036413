import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'

import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons";

import { getDatabase, ref, onValue, get } from "firebase/database";

import { Link } from 'react-router-dom';

export default function Header(props) {

    let navigate = useNavigate();

    const SidebarData = [
        {
          title: "O DOMU",
          path: "/",
          cName: "nav-text"
        },
        {
          title: "DOGODKI",
          path: "/Dogodki",
          cName: "nav-text"
        },
        {
          title: "SKUPINE",
          path: "/Skupine",
          cName: "nav-text"
        }
        ,
        {
          title: "PRAVILNIK",
          path: "/Pravilnik",
          cName: "nav-text"
        }
        ,
        {
          title: "SPREJEM V DOM",
          path: "/SprejemVDom",
          cName: "nav-text"
        }
        ,
        {
          title: "KONTAKT",
          path: "/kontakt",
          cName: "nav-text"
        }
    ];

    useEffect(() => {
    }, [])

    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => {
        setSidebar(!sidebar);
        persistObject("yPositionDogodki", window.pageYOffset)
    }

    const persistObject = (name, object) => {
        localStorage.setItem(name, JSON.stringify(object));
    }

    return (
          <header>

            <>
                <div className="uk-container">
                    {props.pageLink !== "" ? <div className="nav-header">
                        <div>
                            {/*<Link className="nav-link-header" to="/">
                                <p className="margin_top_headline" id="app_title">{props.pageName}</p>
                            </Link>*/}
                            {props.pageName.length > 9 ?  
                            <p className="margin_top_headline app_title app_title_longer">{props.pageName}</p>
                            :
                            <p className="margin_top_headline app_title">{props.pageName}</p>
                            }
                        </div>
                    </div> : 
                    <div className="nav-header-main">
                        {/*<div>
                            <Link className="nav-link-header" to="/"><p className="margin_top_headline" id="app_title"></p></Link>
                        </div>*/}
                    </div>}

                    {/*<img className="nav-img" src="/assets/LOGO-ozadje2.png" alt=""/>*/}

                    <div className={ "black_line" } >

                        {<>

                            <div className="uk-hidden@m navigation_menu">
                                <IconContext.Provider value={{ color: "#FFF" }}>
                                    <div className="uk-child-width-1-3 uk-text-center" uk-grid="true">
                                        <div>
                                            <div className="navbar navbar-menu">
                                                <Link to="#" className="menu-bars show-hide-menu">
                                                    <FaIcons.FaBars onClick={showSidebar} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
                                    <ul className="nav-menu-items" onClick={showSidebar}>
                                        <li className="navbar-toggle">
                                        <Link to="#" className="menu-bars">
                                            <AiIcons.AiOutlineClose />
                                        </Link>
                                        </li>

                                        {SidebarData.map((item, index) => {
                                        return (
                                            <li key={index} className={item.cName}>
                                            <Link to={item.path}>
                                                <span>{item.title}</span>
                                            </Link>
                                            </li>
                                        );
                                        })}
                                    </ul>
                                    </nav>
                                </IconContext.Provider>
                            </div>
                            
                            <>
                                <div className="uk-visible@m navigation_menu">
                                    <div className="nav_link_margin">
                                        {props.pageLink === "" ? 
                                            <Link className="nav_link active_link" to="/">O DOMU</Link> :
                                            <Link className="nav_link" to="/">O DOMU</Link>
                                        }
                                    </div>
                                    <div className="nav_link_margin">
                                        {/*<Link className="nav_link" to="/">DOGODKI</Link>*/}
                                        {props.pageLink === "Dogodki" || props.pageLink === "Dogodek" ? 
                                            <Link className="nav_link active_link" to="/Dogodki">DOGODKI</Link> :
                                            <Link className="nav_link" to="/Dogodki">DOGODKI</Link>
                                        }
                                    </div>
                                    <div className="nav_link_margin">
                                        {props.pageLink === "Skupine" ? 
                                            <Link className="nav_link active_link" to="/Skupine">SKUPINE</Link> :
                                            <Link className="nav_link" to="/Skupine">SKUPINE</Link>
                                        }
                                    </div>
                                    <div className="nav_link_margin">
                                        {props.pageLink === "Pravilnik" ? 
                                            <Link className="nav_link active_link" to="/Pravilnik">PRAVILNIK</Link> :
                                            <Link className="nav_link" to="/Pravilnik">PRAVILNIK</Link>
                                        }
                                    </div>
                                    <div className="nav_link_margin">
                                        {props.pageLink === "SprejemVDom" ? 
                                            <Link className="nav_link nav_link_longer active_link" to="/SprejemVDom">SPREJEM V DOM</Link> :
                                            <Link className="nav_link nav_link_longer" to="/SprejemVDom">SPREJEM V DOM</Link>
                                        }
                                    </div>
                                    <div className="nav_link_margin">
                                        {props.pageLink === "kontakt" ? 
                                            <Link className="nav_link active_link" to="/kontakt">KONTAKT</Link> :
                                            <Link className="nav_link" to="/kontakt">KONTAKT</Link>
                                        }
                                    </div>
                                </div>
                            </>

                        </>}
                    </div>
                </div>
            </>

        </header>
    );
}