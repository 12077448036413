import './App.css';
import Header from "./components/header";
import Footer from "./components/footer";
import "./styles.css";
import Home from './components/Home';
import React, { useEffect, useState } from 'react'
import {
  HashRouter as Router,
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";


import { Helmet } from "react-helmet";
import Kontakt from './components/Kontakt';
import SprejemVDom from './components/SprejemvDom';
import Skupine from './components/Skupine';
import Dogodki from './components/Dogodki';
import Dogodek from './components/Dogodek';
import Pravilnik from './components/Pravilnik';
import DogodekMartinLeaves from './components/DogodekMartinLeaves';

export default function App() {
  const [pageName, setPageName] = useState("");
  const [pageLink, setPageLink] = useState("");

  useEffect(() => {
  }, [])

  const navigate = useNavigate();

  const MyWrapperComponent = (props) => {
    const firebaseConfig = {
      apiKey: "AIzaSyCR-9ul7Xd0tRvFWaaiaKXugMJ4WHzBdTw",
      databaseURL: "https://spletna-stran-sdv-default-rtdb.europe-west1.firebasedatabase.app/",
      storageBucket: "gs://spletna-stran-sdv.appspot.com",
      authDomain: "spletna-stran-sdv.firebaseapp.com",
      projectId: "spletna-stran-sdv",
      storageBucket: "spletna-stran-sdv.appspot.com",
      messagingSenderId: "374512469660",
      appId: "1:374512469660:web:9ee1ee3ca2b54ca7320f59",
      measurementId: "G-RZ6WB5D7QJ"
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const db = getDatabase(app);
    const storage = getStorage(app);


    if (props.pageName !== "Dogodek" && props.pageName !== "Dogodki") {
      persistObject("yPositionDogodki", 0)
    }
    useEffect(() => {
      setPageName(props.pageName)
      setPageLink(props.pageLink)
    }, []);
    return props.children;
  };

  const persistObject = (name, object) => {
    localStorage.setItem(name, JSON.stringify(object));
  }

  /*if (isLoading) {
    return <Loading />;
  }*/
  return (
    <>
      <div>
        <ToastContainer />
        <Helmet>
          <meta charSet="utf-8" />
          <script
            src="https://widget.Cloudinary.com/v2.0/global/all.js"
            type="text/javascript"
          ></script>
        </Helmet>
        <Header pageName={pageName} pageLink={pageLink}/>
        {/* <MusicList /> */}
        <>
          <Routes>

            <Route
              path='/'
              element={
                <MyWrapperComponent pageName="O domu" pageLink="">
                  <Home />
                </MyWrapperComponent>
              }
            />

            <Route
              path='/kontakt'
              element={
                <MyWrapperComponent pageName="Kontakt" pageLink="kontakt">
                  <Kontakt />
                </MyWrapperComponent>
              }
            />

            <Route
              path='/sprejemVDom'
              element={
                <MyWrapperComponent pageName="Sprejem v Dom" pageLink="SprejemVDom">
                  <SprejemVDom />
                </MyWrapperComponent>
              }
            />

            <Route
              path='/pravilnik'
              element={
                <MyWrapperComponent pageName="Pravilnik" pageLink="Pravilnik">
                  <Pravilnik />
                </MyWrapperComponent>
              }
            />

            <Route
              path='/skupine'
              element={
                <MyWrapperComponent pageName="Skupine" pageLink="Skupine">
                  <Skupine />
                </MyWrapperComponent>
              }
            />

            <Route
              path='/dogodki'
              element={
                <MyWrapperComponent pageName="Dogodki" pageLink="Dogodki">
                  <Dogodki />
                </MyWrapperComponent>
              }
            />

            <Route
              path='/dogodek/:dogodekIndex'
              element={
                <MyWrapperComponent pageName="Dogodek" pageLink="Dogodek">
                  <Dogodek />
                </MyWrapperComponent>
              }
            />

            <Route
              path='/MartinOdhaja'
              element={
                <MyWrapperComponent pageName="Dogodek" pageLink="Dogodek">
                  <DogodekMartinLeaves />
                </MyWrapperComponent>
              }
            />

          </Routes>
        </>
        <Footer />
      </div>
    </>
  );
}
