import React, { useEffect, useState } from 'react'
import { getDatabase, onValue, get, ref as firebaseRef } from "firebase/database";
import { getDownloadURL, getStorage, ref as storageRef, listAll } from "firebase/storage";
import { useParams } from 'react-router-dom';

export default function DogodekMartinLeaves() {
    const [dogodek, setDogodek] = useState([]);
    const [images, setImages] = useState([]);

    let { dogodekIndex } = useParams();

    var imageUrls = [];

    useEffect(() => {
        const db = getDatabase();
        const storage = getStorage();

        // const dogodekRef = firebaseRef(db, '/dogodki/' + dogodekIndex);

        // onValue(dogodekRef, (snapshot) => {
        //     const dogodek = 
        //     {
        //         id: snapshot.key,
        //         naslov: snapshot.child('naslov').val(),
        //         datum: snapshot.child('datum').val(),
        //         kratekOpis: snapshot.child('kratekOpis').val(),
        //         opis: snapshot.child('opis').val(),
        //         naslovnaSlika: snapshot.child('naslovnaSlika').val(),
        //         eventImageFolder: snapshot.child('mapaSlike').val(),
        //     }


        //     dogodek.eventImageFolder && listAllEventImages(storage, dogodek.eventImageFolder)

        //     setDogodek(dogodek)
        // })

        const dogodek = 
        {
            id: 1,
            naslov: "Martin odhaja😮",
            datum: "30.9.2023",
            kratekOpis: "hej, sem Martin. Rad igram orgle, pijem kavo in programiram. Če te kdaj zamika, me obišči v 209, vendar pohiti, kajti kmalu me več nebo🫢 Lahko pa prešteješ koliko let si to možnost že imel😉",
            opis: "hej, sem Martin. Rad igram orgle, pijem kavo in programiram. Če te kdaj zamika, me obišči v 209, vendar pohiti, kajti kmalu me več nebo🫢 Lahko pa prešteješ koliko let si to možnost že imel😉",
            naslovnaSlika: "",
            eventImageFolder: "MartinOdhaja",
        }


        dogodek.eventImageFolder && listAllEventImages(storage, dogodek.eventImageFolder)

        setDogodek(dogodek)

    }, [])

    function listAllEventImages(storage, event_folder) { 

        listAll(storageRef(storage, event_folder))
            .then((res) => {
                imageUrls = []
                for (let i = 0; i < res.items.length; i++) {
                    imageUrls.push(null);
                }
                
                res.items.forEach((imageRef, index) => {
                    getDownloadURL(imageRef)
                        .then((url) => {
                            imageUrls[index] = url

                            if (imageUrls.filter(imgUrl => imgUrl === null).length === 0) {
                                setImages(imageUrls)
                            }
                        })
                        .catch((error) => {
                            // Handle any errors
                        });
                });
            }).catch((error) => {
        });
    }

    return (
        <>
            <div className="uk-container">
                <div className='orange_box_novica'>
                    <p className="inside_title_dogodek_group">
                        {dogodek.naslov}
                    </p>
                    <p className="inside_datum_dogodek_group">
                        {dogodek.datum}
                    </p>
                    <p className="inside_text_dogodek">
                        {dogodek.opis}
                    </p>
                </div>

                <div class="uk-child-width-1-3 slika_dogodka_container" uk-grid="true" uk-lightbox="animation: fade">
                    {images.map((imageUrl, index) => {
                        return <div className="uk-width-1-2@s uk-width-1-3@m uk-width-1-1">
                            <a class="uk-inline" href={imageUrl} data-caption={"Slika " + (index + 1)}>
                                <img src={imageUrl} width="1800" height="1200" alt=""/>
                            </a>
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}