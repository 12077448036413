import React, { useEffect, useState } from 'react'
import { getDatabase, ref, onValue, get } from "firebase/database";
import { initializeApp } from "firebase/app";

export default function Skupine() {

    const [nepriloznostneSkupine, setNepriloznostneSkupine] = useState([]);
    const [priloznostneSkupine, setPriloznostneSkupine] = useState([]);

    useEffect(() => {

        const cashedNepriloznostneSkupine = getObject("nepriloznostneSkupine")
        cashedNepriloznostneSkupine && setNepriloznostneSkupine(cashedNepriloznostneSkupine)

        const cashedPriloznostneSkupine = getObject("priloznostneSkupine")
        cashedPriloznostneSkupine && setPriloznostneSkupine(cashedPriloznostneSkupine)

        // const firebaseConfig = {
        //     apiKey: "AIzaSyCR-9ul7Xd0tRvFWaaiaKXugMJ4WHzBdTw",
        //     databaseURL: "https://spletna-stran-sdv-default-rtdb.europe-west1.firebasedatabase.app/",
        //     storageBucket: "gs://spletna-stran-sdv.appspot.com",
        //     authDomain: "spletna-stran-sdv.firebaseapp.com",
        //     projectId: "spletna-stran-sdv",
        //     storageBucket: "spletna-stran-sdv.appspot.com",
        //     messagingSenderId: "374512469660",
        //     appId: "1:374512469660:web:9ee1ee3ca2b54ca7320f59",
        //     measurementId: "G-RZ6WB5D7QJ"
        //   };
          
        //   // Initialize Firebase
        //   const app = initializeApp(firebaseConfig);

        const db = getDatabase();

        const nepriloznostneSkupineRef = ref(db, '/nepriloznostneSkupine');

        onValue(nepriloznostneSkupineRef, (snapshot) => {
            const tmpArray = []

            snapshot.forEach((childSnapshot) => {
                const nepriloznostnaSkupina = 
                {
                    id: childSnapshot.key,
                    naslov_skupine: childSnapshot.child('naslovSkupine').val(),
                    izvajalev: childSnapshot.child('izvajalec').val(),
                    opis: childSnapshot.child('opis').val(),
                    kdaj: childSnapshot.child('kdaj').val(),
                    kje: childSnapshot.child('kje').val(),
                    dodatno: childSnapshot.child('dodatno').val(), //"Povezava do spletne strani DPVZD:",
                    link: childSnapshot.child('link').val(), //"https://drustvo-vzd.si/brezdomci/"
                }

                tmpArray.push(nepriloznostnaSkupina)
            });
            
            if (!cashedNepriloznostneSkupine || JSON.stringify(tmpArray) !== JSON.stringify(cashedNepriloznostneSkupine)) {
                persistObject("nepriloznostneSkupine", tmpArray)
                setNepriloznostneSkupine(tmpArray);
            }
        })

        const priloznostneSkupineRef = ref(db, '/priloznostneSkupine');

        onValue(priloznostneSkupineRef, (snapshot) => {
            const tmpArray2 = []

            snapshot.forEach((childSnapshot) => {
                const priloznostnaSkupina = 
                {
                    id: childSnapshot.key,
                    naslov_skupine: childSnapshot.child('naslovSkupine').val(),
                    izvajalev: childSnapshot.child('izvajalec').val(),
                    opis: childSnapshot.child('opis').val(),
                    kdaj: childSnapshot.child('kdaj').val(),
                    kje: childSnapshot.child('kje').val(),
                    dodatno: childSnapshot.child('dodatno').val(), //"Povezava do spletne strani DPVZD:",
                    link: childSnapshot.child('link').val(), //"https://drustvo-vzd.si/brezdomci/"
                    skupinaStEl: 2, 
                }

                //priloznostnaSkupina.skupinaStEl = priloznostnaSkupina.keys().filter((val) => val != null)

                tmpArray2.push(priloznostnaSkupina)
            });

            if (!cashedPriloznostneSkupine || JSON.stringify(tmpArray2) !== JSON.stringify(cashedPriloznostneSkupine)) {
                persistObject("priloznostneSkupine", tmpArray2)
                setPriloznostneSkupine(tmpArray2);
            }
        })
    }, [])

    const persistObject = (name, object) => {
        localStorage.setItem(name, JSON.stringify(object));
    }

    const getObject = (name) => {
        return JSON.parse(localStorage.getItem(name));
    }

    return (
        <>
            <div className="uk-container">

                <div className="uk-child-width-1-2" uk-grid="true">

                    <div className="uk-width-1-2@m uk-width-1/1">

                        {nepriloznostneSkupine.map((skupina, index) => {
                            var className = "groups_orange_box_sredina"
                            if (index == 0) {
                                className = 'groups_orange_box_zgoraj'
                            }
                            else if (index == nepriloznostneSkupine.length - 1) {
                                className = 'groups_orange_box_spodaj'
                            }

                            return <div className={className}>
                                <p className="inside_text_right_boxes_heading">
                                    {skupina.naslov_skupine}
                                </p>
                                {skupina.izvajalev && <p className="inside_text_right_boxes_izvajalec">
                                    {skupina.izvajalev}
                                </p>}
                                {skupina.opis && <p className="inside_text_right_boxes_izvajalec">
                                    {skupina.opis}
                                </p>}
                                {skupina.kdaj && <p className="inside_text_right_boxes_kdaj_kje_dodatno_link">
                                    KDAJ: {skupina.kdaj}
                                </p>}
                                {skupina.kje && <p className="inside_text_right_boxes_kdaj_kje_dodatno_link">
                                    KJE: {skupina.kje}
                                </p>}
                                {skupina.dodatno && <p className="inside_text_right_boxes_kdaj_kje_dodatno_link">
                                    {skupina.dodatno}
                                </p>}
                                {skupina.link && <a href={skupina.link} target="_blank" className="inside_text_right_boxes_kdaj_kje_dodatno_link">
                                    {skupina.link}
                                </a>}
                                <div className="groups_orange_box_margin_spodaj"></div>
                            </div>
                        })}

                    </div>

                    <div className="uk-width-1-2@m uk-width-1/1">

                        {priloznostneSkupine.map((skupina, index) => {
                            var className = "groups_darker_box_sredina"
                            if (index == 0) {
                                className = 'groups_darker_box_zgoraj'
                            }
                            else if (index == priloznostneSkupine.length - 1) {
                                className = 'groups_darker_box_spodaj'
                            }

                            return <div className={className}>
                                {skupina.skupinaStEl <= 2 && <div className="groups_darker_box_margin_zgoraj"></div>}
                                <p className="inside_text_darker_boxes_heading">
                                    {skupina.naslov_skupine}
                                </p>
                                {skupina.izvajalev && <p className="inside_text_darker_boxes_izvajalec">
                                    {skupina.izvajalev}
                                </p>}
                                {skupina.opis && <p className="inside_text_darker_boxes_izvajalec">
                                    {skupina.opis}
                                </p>}

                                {skupina.skupinaStEl > 2 ? 
                                    <>
                                        {skupina.kdaj && <p className="inside_text_darker_boxes_kdaj_kje_dodatno_link">
                                            KDAJ: {skupina.kdaj}
                                        </p>}
                                        {skupina.kje && <p className="inside_text_darker_boxes_kdaj_kje_dodatno_link">
                                            KJE: {skupina.kje}
                                        </p>}
                                        {skupina.dodatno && <p className="inside_text_darker_boxes_kdaj_kje_dodatno_link">
                                            {skupina.dodatno}
                                        </p>}
                                        {skupina.link && <a href={skupina.link} target="_blank" className="inside_text_darker_boxes_kdaj_kje_dodatno_link">
                                            {skupina.link}
                                        </a>}
                                    </> :
                                    <>
                                        <p className="inside_text_darker_boxes_pril_sk_text">
                                            Priložnostna skupina
                                        </p>
                                    </>}
                                <div className="groups_orange_box_margin_spodaj"></div>
                            </div>
                        })}

                    </div>
                </div>
                
            </div>
        </>
    )
}